import React from 'react'
import styled from 'styled-components'
import {
  SpacingSizes,
  GENERAL_FONT_VALUES,
  GeneralFontNames,
  LineHeights,
} from '../../../../clarity'

const Section = styled.section`
  max-width: 1440px;
  padding: ${SpacingSizes.md};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 550px;
  margin-top: ${SpacingSizes.xl};
  display: flex;
  justify-content: center;
`

const SectionTitle = styled.h1`
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.playfair_48]};
  line-height: ${LineHeights[74]};
`

const BlingsDisabled = () => (
  <Section>
    <SectionTitle>
      This page is currently disabled.
    </SectionTitle>
  </Section>
)

export default BlingsDisabled
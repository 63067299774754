import React from 'react'
import styled from 'styled-components'
import {
  SpacingSizes,
  GENERAL_FONT_VALUES,
  GeneralFontNames,
  MediumPrimaryButton,
} from '../../../../clarity'
import common from '../../../styles/common.module.css'
import { size } from '../../../styles/constants'
import BackgroundEllipseBig from '../../../images/icons/exp/ellipse_big.svg'
import BackgroundEllipseSmall from '../../../images/icons/exp/ellipse_small.svg'
import { HEADER_ANIMATION_SCROLL_REF } from './blingsDesktop'

export const PaddingForPseudoElement = 70

const Section = styled.section`
  max-width: ${size.a};
  padding: 64px ${SpacingSizes.smd} ${PaddingForPseudoElement + 10}px;
  background: none;
  margin-top: ${SpacingSizes.xl};
  &:before,
  &:after {
    content: "";
    position: absolute;
    background: 0 0 no-repeat;
  }
  &:before {
    left: -225.51px;
    top: -233px;
    transform: rotate(0.41deg);
    background-size: cover;

    width: 515.73px;
    height: 476.97px;
    opacity: 0.6;
    background-image: url(${BackgroundEllipseBig});
  }
  &:after {
    width: 190px;
    height: 390px;
    right: -120px;
    transform: rotate(0.41deg);
    top: 120px;
    background-image: url(${BackgroundEllipseSmall});
    z-index: -1;
  }
`

const SectionTitle = styled.h1`
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.playfair_22]};
  line-height: 1;
  margin-bottom: ${SpacingSizes.xs};
`

const SectionSubTitle = styled.h1`
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.playfair_48]};
  line-height: 1;
  margin-bottom: ${SpacingSizes.xs};
`

const PlayButtonContainer = styled.div`
  margin: ${SpacingSizes.lg} 0 0;
`

const SectionWrapper = styled.div`
  overflow: hidden;
`
declare global {
  interface Window {
    BlingsPlayer: any
  }
}
const isBrowser = () => typeof window !== 'undefined'

const BlingsMobile = (blingsData: any) => {
  const itemId = isBrowser()
    ? window.location.search.replace('?itemId=', '')
    : ''

  const userName = blingsData.blingsData?.data?.user?.first_name
  return userName ? (
    <>
      <SectionWrapper className={[common.posRel]}>
        <Section
          className={[
            common.dFlex,
            common.flexCol,
            common.margin0auto,
            common.flexJustifySpaceBetween,
          ]}
          ref={HEADER_ANIMATION_SCROLL_REF}
        >
          <div>
            <SectionTitle className={[common.textCenter]}>Welcome</SectionTitle>
            <SectionSubTitle className={[common.textCenter]}>
              {userName}
            </SectionSubTitle>
            <span>
              We’ve created a short video just for you to explain how Worthy
              works. Click to watch!
            </span>
            <PlayButtonContainer
              className={[common.dFlex, common.flexCol, common.flexAlignCenter]}
            >
              <a
                href={`/blingsplayer?itemId=${itemId}`}
                style={{ textDecoration: 'none' }}
              >
                <MediumPrimaryButton
                  overrideWrapperCSS={{ width: '100%', textAlign: 'center' }}
                  overrideCSS={{ maxWidth: '283px', height: '48px' }}
                  dataAutomation="button-playBlings-mobile"
                  click={() => {
                  }}
                  text="Play Video"
                />
              </a>
            </PlayButtonContainer>
          </div>
        </Section>
      </SectionWrapper>
    </>
  ) : null
}

export default BlingsMobile

import React, { useEffect, useState } from 'react'
import RecentDealsSection from '../components/homepage/recentDealsSection/RecentDealsSection'
import BlingsSection from '../components/blings/blingsSection/blingsSection'
import OurProcess from '../components/homepage/ourProcess'
import ReviewsSection from '../components/homepage/reviewsSection'
import FAQSection from '../components/homepage/faqSection/faqSection'
import TrustUs from '../components/homepage/trustPilotNLogos'
import { TestimonialsSection } from '../components/homepage/testimonials/TestimonialsSection'
import MobileLayout from '../components/layout/mobileLayout'
import Layout from '../components/layout/layout'
import { useBP } from '../data/objects/breakpoints'
import { renderOnMount } from '../components/forceUpdate'
import { BlingsDataInterface, initialState, getBlingsData } from '../data/api/blings'
import BlingsDisabled from '../components/blings/blingsSection/blingsDisabled'

declare global {
  interface Window {
    BlingsPlayer: any
  }
}

const isBrowser = () => typeof window !== 'undefined'

const Blings = () => {
  const [data, setData] = useState<BlingsDataInterface>(initialState)
  const itemId = isBrowser() ? window.location.search.replace('?itemId=', '') : ''
  const { hideBlingsPlayer, error } = data
  useEffect(() => {
    async function fetchData() {
      const fetchedData = await getBlingsData(itemId)
      setData(fetchedData)
    }
    fetchData()
  }, [])

  const main = (
    <>
      <BlingsSection data={data} />
      <TrustUs />
      <OurProcess />
      <RecentDealsSection />
      <TestimonialsSection />
      <ReviewsSection />
      <FAQSection />
    </>
  )

  const pageForGAEvents = 'Blings'
  const renderKey = renderOnMount()

  const dimentions = useBP().get().fitsB ? (
    <MobileLayout key={renderKey} page={pageForGAEvents}>
      {hideBlingsPlayer || error ? <BlingsDisabled /> : main}
    </MobileLayout>
  ) : (
    <Layout key={renderKey} page={pageForGAEvents} transparentHeader>
      {hideBlingsPlayer || error ? <BlingsDisabled /> : main}
    </Layout>
  )

  return dimentions
}
export default Blings

import axios from 'axios'
import { envUrls } from '../objects/env'

export const getBlingsData = async (itemId: string) => {
  try {
    const result = await axios.get(
      `${envUrls.contentBE}/get_blings_data?itemId=${itemId}`,
    )
    return result.data
  } catch (error: any) {
    return { error }
  }
}
export interface BlingsDataInterface {
  name: string
  product_name: string
  main_product_type: string
  reviews_cta: string
  drop_off_cta: string
  schedule_pickup_cta: string
  similar_p_name1: string
  similar_p_name2: string
  similar_p_name3: string
  similar_p_price3: string
  similar_p_price2: string
  similar_p_price1: string
  similar_product3: string
  similar_product2: string
  similiar_product1: string
  product_detail_4: string
  product_detail_3: string
  product_detail_2: string
  product_detail_1: string
  data?: object
  key?: number
  user?: object
  hideBlingsPlayer?: boolean
  error?: any
}

export const initialState = {
  name: '',
  product_name: '',
  main_product_type: '',
  reviews_cta: '',
  drop_off_cta: '',
  schedule_pickup_cta: '',
  similar_p_name1: '',
  similar_p_name2: '',
  similar_p_name3: '',
  similar_p_price3: '',
  similar_p_price2: '',
  similar_p_price1: '',
  similar_product3: '',
  similar_product2: '',
  similiar_product1: '',
  product_detail_4: '',
  product_detail_3: '',
  product_detail_2: '',
  product_detail_1: '',
}

export const transformBlingsData = (data: any): BlingsDataInterface => {
  let presentItems = {}
  if (data.similar_items?.length >= 3) {
    presentItems = {
      similar_p_name1: data.similar_items[0]?.headline
        .split(' ')
        .slice(0, 3)
        .join(' '),
      similar_p_name2: data.similar_items[1]?.headline
        .split(' ')
        .slice(0, 3)
        .join(' '),
      similar_p_name3: data.similar_items[2]?.headline
        .split(' ')
        .slice(0, 3)
        .join(' '),
      similar_p_price3: data.similar_values[0],
      similar_p_price2: data.similar_values[1],
      similar_p_price1: data.similar_values[2],
      similar_product3: data.similar_images[0]?.image.medium.url,
      similar_product2: data.similar_images[1]?.image.medium.url,
      similiar_product1: data.similar_images[2]?.image.medium.url,
    }
  } else if (data.static_items) {
    presentItems = {
      similar_p_name1: data.static_items[0]?.headline
        .split(' ')
        .slice(0, 3)
        .join(' '),
      similar_p_name2: data.static_items[1]?.headline
        .split(' ')
        .slice(0, 3)
        .join(' '),
      similar_p_name3: data.static_items[2]?.headline
        .split(' ')
        .slice(0, 3)
        .join(' '),
      similar_p_price3: data.static_values[0],
      similar_p_price2: data.static_values[1],
      similar_p_price1: data.static_values[2],
      similar_product3: data.static_images[0]?.image.medium.url,
      similar_product2: data.static_images[1]?.image.medium.url,
      similiar_product1: data.static_images[2]?.image.medium.url,
    }
  }

  const caratWeight = data.current_item_properties?.caratWeight || ''
  const style = data.current_item_properties?.style || ''
  const color = data.current_item_properties?.color || ''
  const clarity = data.current_item_properties?.clarity || ''

  return {
    ...initialState,
    ...presentItems,
    name: data.user?.first_name,
    main_product_type: 'Ring',
    product_name: data.current_item_properties?.itemDescription,
    reviews_cta: 'https://www.worthy.com/reviews',
    drop_off_cta: `https://app.worthy.com/ll/${data.current_item_properties?.publicId}`,
    schedule_pickup_cta: `https://app.worthy.com/ll/${data.current_item_properties?.publicId}`,
    product_detail_1: `${caratWeight} CT`,
    product_detail_2: style.charAt(0).toUpperCase() + style.slice(1),
    product_detail_3: color.charAt(0).toUpperCase() + color.slice(1),
    product_detail_4: clarity.charAt(0).toUpperCase() + clarity.slice(1),
  }
}

import React from 'react'
import styled from 'styled-components'
import { animated } from 'react-spring'
import {
  Popup,
  SpacingSizes,
} from '../../../../clarity/index'
import common from '../../../styles/common.module.css'
import { Overlay } from '../../../styles/styled/commonStyled'
import xicon from '../../../images/icons/x-icon.svg'
import { WrapperProps } from './blingsHelper'
import { usePopUpAnimation } from '../../../hooks/usePopUpAnimation'

const PopupContent = styled.div`
  padding: ${SpacingSizes.sm} ${SpacingSizes.md} ${SpacingSizes.md};
`

const PopupContainer = styled.div`
  z-index: 3;
  position: fixed;
`

const CloseIcon = styled.img`
  margin-left: auto;
  display: block;
  cursor: pointer;
`

declare global {
  interface Window {
    BlingsPlayer: any
  }
}

const BlingsPopup = (props: WrapperProps) => {
  const [styles, leaveAnimation] = usePopUpAnimation()

  const onClose = () => {
    leaveAnimation(props.onClose)
  }
  const itemId = window.location.search

  const iFrame = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <iframe
        title="blingsPlayer"
        src={`/blingsplayer${itemId}`}
        style={{ marginTop: '100px', width: '339px', height: '600px' }} // width = height / 1.766
      />
    </div>
  )

  return (
    <>
      <Overlay
        as={animated.div}
        style={{ opacity: styles.opacity, transition: styles.transition }}
        className={[
          common.dFlex,
          common.flexAlignCenter,
          common.flexJustifyCenter,
          common.w100,
        ]}
        onClick={onClose}
      >
        <PopupContainer
          onClick={(e: Event) => {
            e.stopPropagation()
          }}
        >
          <Popup>
            <CloseIcon src={xicon} alt="x" onClick={onClose} />
            <PopupContent>
              {iFrame}
            </PopupContent>
          </Popup>
        </PopupContainer>
      </Overlay>
    </>
  )
}
export default BlingsPopup

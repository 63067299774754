import React from 'react';
import BlingsMobile from './blingsMobile'
import BlingsDesktop from './blingsDesktop'
import { useBP } from '../../../data/objects/breakpoints'
import { renderOnMount } from '../../forceUpdate'

const BlingsSection = (blingsData: any) => {
  const renderKey = renderOnMount()
  return (
    <>
      {
        useBP().get().fitsA
          ? <BlingsMobile blingsData={blingsData} key={renderKey} />
          : <BlingsDesktop blingsData={blingsData} key={renderKey} />
      }
    </>
  )
};

export default BlingsSection;

import styled from 'styled-components'
import { fadeUpWrapper } from '../../../animations/ScrollFadeUpAnimation'
import { to } from '../../../styles/constants'

export interface WrapperProps {
  onClose: () => void
}

export const BlingsArea = fadeUpWrapper(styled.div`
  @media ${to.bpC} {
    max-width: 533px;
  }
`)

export const expSlugs = ['opt_blings_flow_reposition']

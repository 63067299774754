import React, { useState } from 'react'
import styled from 'styled-components'
import { SpacingSizes, GENERAL_FONT_VALUES, GeneralFontNames, LineHeights } from '../../../../clarity'
import { LargeRunningText } from '../../../styles/styled/commonText'
import common from '../../../styles/common.module.css'
import PinkBG from '../../../images/homepage/pinkLadyDesktopBG.jpg'
import PinkTabletBG from '../../../images/homepage/pinkLadyTabletBG.jpg'
import { size, to, from } from '../../../styles/constants'
import { BlingsArea } from './blingsHelper'
import BlingsPopup from './blingsPopup'
import { useBP } from '../../../data/objects/breakpoints'
import { renderOnMount } from '../../forceUpdate'
import playButton from '../../../images/blings/trans-sub-play.svg'
import playButtonHover from '../../../images/blings/trans-sub-play-hover.svg'
import { GA } from '../../../data/GA'

const Section = styled.section`
  max-width: ${size.e};
  padding: ${SpacingSizes.md};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 550px;

  @media ${to.bpD} {
    background-image: url(${PinkTabletBG});
    padding-top: ${SpacingSizes.xxxl};
  }

  @media ${to.bpB} {
    margin-top: ${SpacingSizes.xl};
  }
`

const SectionWrapper = styled.div`
  background-image: url(${PinkBG});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${to.bpD} {
    background-image: none;
  }

  @media ${from.bpD} {
    padding-top: ${SpacingSizes.xl};
  }
`

const SectionTitle = styled.h1`
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.playfair_48]};
  line-height: ${LineHeights[74]};
`

const VideoTextContainer = styled.div`
  align-items: baseline;
  margin: 0;
`

const LeftWrapper = styled.div`
  width: 50%;
  @media ${to.bpD} {
    width: 45%;
  }
  @media ${to.bpC} {
    width: 100%;
  }
`

const PlayButtonWrapper = styled.div`
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`

const PlayButton = styled.img`
  margin-right: 50%;
  margin-top 20%;
`

const SectionContent = (
  showPopup: boolean,
  setShowPopup: (showPopup: boolean) => void,
  blingsData: any,
  setPlayHover: any,
  playHover: boolean
) => {
  const openPopup = () => {
    setShowPopup(true)
    GA.aboveTheFoldEvent()
  }

  const { fitsC } = useBP().get()
  const renderKey = renderOnMount()
  const userName = blingsData.blingsData?.data?.user?.first_name
  return (
    <>
      <LeftWrapper className={[common.dFlex, common.flexJustifyCenter, common.flexCol]} key={`${renderKey} left`}>
        <BlingsArea className={[common.dFlex, common.flexCol, common.flexJustifyCenter, common.margin0auto]}>
          {userName ? (
            <>
              <SectionTitle>{`Hi ${userName}!`}</SectionTitle>
              <VideoTextContainer className={[common.dFlex, fitsC && common.flexJustifyCenter]}>
                <LargeRunningText>
                  We’ve created a short video just for you to explain
                  <br />
                  how Worthy works. Click to watch!
                </LargeRunningText>
              </VideoTextContainer>
            </>
          ) : null}
        </BlingsArea>
      </LeftWrapper>
      <PlayButtonWrapper>
        <PlayButton
          onClick={openPopup}
          onMouseOver={() => setPlayHover(true)}
          onMouseOut={() => setPlayHover(false)}
          src={playHover ? playButtonHover : playButton}
          alt="Play"
        />
      </PlayButtonWrapper>
      {showPopup && (
        <BlingsPopup
          onClose={() => {
            setShowPopup(false)
          }}
        />
      )}
    </>
  )
}

export const HEADER_ANIMATION_SCROLL_REF = React.createRef()

const BlingsDesktop = (blingsData: any) => {
  const [showPopup, setShowPopup] = useState(false)
  const { fitsC } = useBP().get()
  const renderKey = renderOnMount()
  const [playHover, setPlayHover] = useState(false)

  return (
    <>
      <SectionWrapper className={[common.w100]} key={renderKey}>
        <Section
          className={[
            common.dFlex,
            common.margin0auto,
            fitsC ? common.flexJustifyCenter : common.flexJustifySpaceBetween,
            fitsC && common.textCenter,
          ]}
          ref={HEADER_ANIMATION_SCROLL_REF}
        >
          {SectionContent(showPopup, setShowPopup, blingsData, setPlayHover, playHover)}
        </Section>
      </SectionWrapper>
    </>
  )
}

export default BlingsDesktop
